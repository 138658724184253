// Color palettes from the Material Design spec.
// See https://www.google.com/design/spec/style/color.html
//
// Contrast colors are hard-coded because it is too difficult (probably impossible) to
// calculate them. These contrast colors are pulled from the public Material Design spec swatches.
// While the contrast colors in the spec are not prescriptive, we use them for convenience.

@import '~@angular/material/theming';
@import './mat-palette';
@import './theme-variables';

@mixin mat-chips-typography($config) {
  .mat-chip {
    font-family: 'Nunito Sans';
    font-size: mat-font-size($config, body-1);
  }
}

@mixin mat-button-typography($config) {
  .mat-button, .mat-raised-button, .mat-flat-button {
    font-family: 'Nunito Sans';
    font-weight: 700;
    font-size: 12px !important;
    min-width: 10px !important;
    text-transform: uppercase;
  }
}

// setup our custom typography config
$typography-config: mat-typography-config($font-family: 'Nunito Sans');

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat-core($typography-config); // pass in our custom typography config

// Define the app theme.
//
// The "mat-palette" Sass function takes the extended
// base color palette it was given and returns it
// with a few extra properties like:
// - default
// - default-lighter
// - default-darker
// - default-contrast
// - default-lighter-contrast
// - default-darker-contrast
$primary:               mat-palette($primary-palette, 500, 100, 700);
$accent:                mat-palette($accent-palette, 500, 100, 700);
$warn:                  mat-palette($red-palette);
$app-theme:                     mat-light-theme($primary, $accent, $warn);


// Include the default theme styles.
@include angular-material-theme($app-theme);

.main-logo {
  margin-top: -4px;
}
